import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowRight,
} from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import EventiSlider from "../components/EventiSlider/EventiSlider";
import { splitToChunks } from "../utils/array";

const ProfessionePage = ({ data }) => {
  const {
    professioniJson: professione,
    allDisciplineJson: { nodes: discipline },
    allEventiFad: { nodes: eventiFad },
    allEventiRes: { nodes: eventiRes },
    allEventiFsc: { nodes: eventiFsc },
    totaleEventi: { totalCount },
  } = data;

  const disciplineChunks = splitToChunks([...discipline], 4);

  return (
    <Layout pageTitle={`Corsi ECM per ${professione.descrizione}`}>
      <section className="position-relative">
        <StaticImage
          src="../images/header-provider.jpg"
          className="img-header-home"
          alt={`Professione ${professione.descrizione}`}
          title={`Professione ${professione.descrizione}`}
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-4 font-weight-bold">
              CORSI ECM per {professione.descrizione}
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM per {professione.descrizione}{" "}
              disponibili.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid">
        <div className="row p-5">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Seleziona la tua disciplina per visualizzare i corsi ECM FAD
              disponibili
            </h2>
          </div>
          {disciplineChunks.map((disciplineChunk, index) => (
            <div key={index} className="col-12 col-lg-3">
              <ul className="list-unstyled">
                {disciplineChunk.map(({ disciplina, fadPath: path }) => (
                  <li key={disciplina} className="mb-2">
                    <Link to={path} title={`Corsi ECM FAD per ${disciplina}`}>
                      Corsi ECM FAD per {disciplina}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <section className="container-fluid p-5">
        <h2 className="mb-4 text-center titolo-sezione">
          Corsi ECM FAD in evidenza
        </h2>
        <EventiSlider eventi={eventiFad} />
        <div className="row px-5 py-3">
          <div className="col-12 text-center">
            <Link to={professione.fadPath} className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row p-5">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Seleziona la tua disciplina per visualizzare i corsi ECM RES
              disponibili
            </h2>
          </div>
          {disciplineChunks.map((disciplineChunk, index) => (
            <div key={index} className="col-12 col-lg-3">
              <ul className="list-unstyled">
                {disciplineChunk.map(({ disciplina, resPath: path }) => (
                  <li key={disciplina} className="mb-2">
                    <Link to={path} title={`Corsi ECM RES per ${disciplina}`}>
                      Corsi ECM RES per {disciplina}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <section className="container-fluid p-5">
        <h2 className="mb-4 text-center titolo-sezione">
          Corsi ECM RES in evidenza
        </h2>
        <EventiSlider eventi={eventiRes} />
        <div className="row px-5 py-3">
          <div className="col-12 text-center">
            <Link to={professione.resPath} className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row p-5">
          <div className="col-12">
            <h2 className="my-4 text-center titolo-sezione">
              Seleziona la tua disciplina per visualizzare i corsi ECM FSC
              disponibili
            </h2>
          </div>
          {disciplineChunks.map((disciplineChunk, index) => (
            <div key={index} className="col-12 col-lg-3">
              <ul className="list-unstyled">
                {disciplineChunk.map(({ disciplina, fscPath: path }) => (
                  <li key={disciplina} className="mb-2">
                    <Link to={path} title={`Corsi ECM FSC per ${disciplina}`}>
                      Corsi ECM FSC per {disciplina}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <section className="container-fluid p-5">
        <h2 className="mb-4 text-center titolo-sezione">
          Corsi ECM FSC in evidenza
        </h2>
        <EventiSlider eventi={eventiFsc} />
        <div className="row px-5 py-3">
          <div className="col-12 text-center">
            <Link to={professione.fscPath} className="btn btn-outline-primary">
              Vedi tutti i corsi <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($descrizione: String = "") {
    professioniJson(descrizione: { eq: $descrizione }) {
      id
      descrizione
      fadPath: gatsbyPath(filePath: "/fad/{ProfessioniJson.descrizione}")
      resPath: gatsbyPath(
        filePath: "/residenziali/{ProfessioniJson.descrizione}"
      )
      fscPath: gatsbyPath(filePath: "/fsc/{ProfessioniJson.descrizione}")
    }
    allDisciplineJson(filter: { professione: { eq: $descrizione } }) {
      nodes {
        disciplina
        fadPath: gatsbyPath(
          filePath: "/fad/{DisciplineJson.professione}/{DisciplineJson.disciplina}"
        )
        fscPath: gatsbyPath(
          filePath: "/fsc/{DisciplineJson.professione}/{DisciplineJson.disciplina}"
        )
        resPath: gatsbyPath(
          filePath: "/residenziali/{DisciplineJson.professione}/{DisciplineJson.disciplina}"
        )
      }
    }
    totaleEventi: allEventiJson(
      filter: {
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
      }
    ) {
      totalCount
    }
    allEventiFad: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FAD" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiRes: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "RES" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiFsc: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FSC" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;

export default ProfessionePage;
